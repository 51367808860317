import { StateAdapt, configureStateAdapt } from '@state-adapt/rxjs';
import { actionSanitizer, stateSanitizer, getId } from '@state-adapt/core';
import { inject, InjectionToken } from '@angular/core';

/**
  ## ![StateAdapt](https://miro.medium.com/max/4800/1*qgM6mFM2Qj6woo5YxDMSrA.webp|width=14) `provideStore`

  `provideStore` takes in a {@link ConfigureStateAdaptOptions} object and
  returns a provider for {@link StateAdapt} that you can add
  to the `providers` array in your `AppModule` or `main.ts` file to make
  {@link adapt} and {@link watch} available to use in your components and services.

  #### Example: Using `provideStore` in AppModule for devtools setup without selectors

  ```ts
  import { NgModule } from '@angular/core';
  import { BrowserModule } from '@angular/platform-browser';
  import { actionSanitizer, stateSanitizer } from '@state-adapt/core';
  import { provideStore } from '@state-adapt/angular';

  import { AppComponent } from './app.component';

  @NgModule({
    imports: [BrowserModule],
    declarations: [AppComponent],
    providers: [
      provideStore({
        devtools: (window as any)?.__REDUX_DEVTOOLS_EXTENSION__?.({
          actionSanitizer,
          stateSanitizer,
        }),
        showSelectors: false,
      }),
    ],
  })
  export class AppModule {}
  ```

  #### Example: Using `provideStore` in a main.ts file for no devtools

  ```ts
  import { bootstrapApplication } from '@angular/platform-browser';
  import { actionSanitizer, stateSanitizer } from '@state-adapt/core';
  import { provideStore } from '@state-adapt/angular';

  import { AppComponent } from './app/app.component';

  bootstrapApplication(AppComponent, {
    providers: [
      provideStore({}),
      // ...
    ]
  });
  ```
 */
function provideStore(options) {
  return {
    provide: StateAdapt,
    useValue: configureStateAdapt(options)
  };
}
const enableReduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION__?.({
  actionSanitizer,
  stateSanitizer
});
/**
  ## ![StateAdapt](https://miro.medium.com/max/4800/1*qgM6mFM2Qj6woo5YxDMSrA.webp|width=14) `defaultStoreProvider`

  `defaultStoreProvider` is the default provider for {@link StateAdapt}, and
  is the easiest way to get started with StateAdapt in Angular. Simply add
  `defaultStoreProvider` to your `providers` array in your `AppModule` or `main.ts` file,
  and you can use {@link adapt} and {@link watch} in your components and services.

  Use {@link provideStore} for more advanced configuration.

  #### Example: Using `defaultStoreProvider` for basic setup

  ```ts
  import { NgModule } from '@angular/core';
  import { BrowserModule } from '@angular/platform-browser';
  import { defaultStoreProvider } from '@state-adapt/angular';

  import { AppComponent } from './app.component';

  @NgModule({
    imports: [BrowserModule],
    declarations: [AppComponent],
    providers: [defaultStoreProvider],
  })
  export class AppModule {}
  ```

  #### Example: Using `defaultStoreProvider` in a main.ts file

  ```ts
  import { bootstrapApplication } from '@angular/platform-browser';
  import { defaultStoreProvider } from '@state-adapt/angular';

  import { AppComponent } from './app/app.component';

  bootstrapApplication(AppComponent, {
    providers: [
      defaultStoreProvider,
      // ...
    ]
  });
  ```
 */
const defaultStoreProvider = provideStore({
  devtools: enableReduxDevTools
});

/*
Ask ChatGPT:

I'm using TypeScript. I have documentation for a class method `StateAdapt.adapt` that needs to be modified for a wrapper function called `adapt`. Here are the differences:
  - The phrase "`adapt` wraps {@link StateAdapt.adapt}, calling `inject(StateAdapt)` to get the instance of {@link StateAdapt} to use." is added right after the copilot tip
  - Examples are modified to show usage in Angular classes called MyComponent for simple examples, or MyService for complex examples.
    Include the entire class definition starting with`export class MyComponent { or export class MyService {
    Write the logging code in the class constructor.
  - Replace {@link StateAdapt.watch} with {@link watch}
  - Replace StateAdapt.adapt with adapt in the first sentence

Don't skip anything. Give me the entire documentation from start to end.

Here's an example before:

```typescript
  const nameChange$ = new Source<string>('nameChange$');
  const nameReset$ = new Source<void>('nameReset$');

  const name = adapt('John', {
    sources: {
      set: nameChange$,
      reset: nameReset$,
    },
    path: 'name',
  });

  name.state$.subscribe(console.log); // Logs 'John'

  nameChange$.next('Johnsh'); // logs 'Johnsh'
  nameReset$.next(); // logs 'John'
```

after:

```typescript
  export class MyService {
    nameChange$ = new Source<string>('nameChange$');
    nameReset$ = new Source<void>('nameReset$');

    name = adapt('John', {
      sources: {
        set: this.nameChange$,
        reset: this.nameReset$,
      },
      path: 'name',
    });

    constructor() {
      this.name.state$.subscribe(console.log); // Logs 'John'

      this.nameChange$.next('Johnsh'); // logs 'Johnsh'
      this.nameReset$.next(); // logs 'John'
    }
  }
```

Don't skip anything. Give me the entire documentation from start to end.

Here are the docs:



Don't skip anything. Give me the entire documentation from start to end.
*/
/**
  ## ![StateAdapt](https://miro.medium.com/max/4800/1*qgM6mFM2Qj6woo5YxDMSrA.webp|width=14) `adapt`

  > Copilot tip: Copy examples into your file or click to definition to open file with context for better Copilot suggestions.

  `adapt` wraps {@link adapt}, calling `inject(StateAdapt)` to get the instance of {@link StateAdapt} to use.

  `adapt` creates a store that will manage state while it has subscribers.

  ### Example: initialState only
  `adapt(initialState)`

  The simplest way to use `adapt` is to only pass it an initial state. `adapt` returns a store object that is ready to start managing state once it has subscribers.
  The store object comes with `set` and `reset` methods for updating state, and a `state$` observable of the store's state.

  ```typescript
  export class MyComponent {
    name = adapt('John');

    constructor() {
      this.name.state$.subscribe(console.log); // logs 'John'
      this.name.set('Johnsh'); // logs 'Johnsh'
      this.name.reset(); // logs 'John'
    }
  }
  ```

  Usually you won't manually subscribe to state like this, but you can if you want the store to immediately start managing state
  and never clean it up.

  ### Example: Using an adapter
  `adapt(initialState, adapter)`

  You can also pass in a state {@link Adapter} object to customize the state change functions and selectors.

  ```typescript
  export class MyComponent {
    name = adapt('John', {
      concat: (state, payload: string) => state + payload,
      selectors: {
        length: state => state.length,
      },
    });

    constructor() {
      this.name.state$.subscribe(console.log); // Logs 'John'
      this.name.length$.subscribe(console.log); // Logs 4
      this.name.concat('sh'); // logs 'Johnsh' and 6
      this.name.reset(); // logs 'John' and 4
    }
  }
  ```

  ### Example: Using {@link AdaptOptions}
  `adapt(initialState, { adapter, sources, path })`

  You can also define an adapter, sources, and/or a state path as part of an {@link AdaptOptions} object.

  Sources allow the store to declaratively react to external events rather than being commanded
  by imperative callback functions.

  ```typescript
  export class MyService {
    tick$ = interval(1000).pipe(toSource('tick$'));

    clock = adapt(0, {
      adapter: {
        increment: state => state + 1,
      },
      sources: this.tick$, // or [this.tick$], or { set: this.tick$ }, or { set: [this.tick$] }
      path: 'clock',
    });

    constructor() {
      this.clock.state$.subscribe(console.log); // Logs 0, 1, 2, 3, etc.
    }
  }
  ```

  There are 4 possible ways sources can be defined:

  1\. A source can be a single {@link Source} or [Observable](https://rxjs.dev/guide/observable)<{@link Action}<{@link State}>>. When the source emits, it triggers the store's `set` method
  with the payload.

  #### Example: Single source

  ```typescript
  export class MyService {
    nameChange$ = new Source<string>('nameChange$');

    name = adapt('John', {
      sources: this.nameChange$,
      path: 'name',
    });

    constructor() {
      this.name.state$.subscribe(console.log); // Logs 'John'
      this.nameChange$.next('Johnsh'); // logs 'Johnsh'
    }
  }
  ```

  2\. A source can be an array of {@link Source} or [Observable](https://rxjs.dev/guide/observable)<{@link Action}<{@link State}>>. When any of the sources emit, it triggers the store's `set`
    method with the payload.

  #### Example: Array of sources

  ```typescript
  export class MyService {
    nameChange$ = new Source<string>('nameChange$');
    nameChange2$ = new Source<string>('nameChange2$');

    name = adapt('John', {
      sources: [this.nameChange$, this.nameChange2$],
      path: 'name',
    });

    constructor() {
      this.name.state$.subscribe(console.log); // Logs 'John'
      this.nameChange$.next('Johnsh'); // logs 'Johnsh'
      this.nameChange2$.next('Johnsh2'); // logs 'Johnsh2'
    }
  }
  ```

  3\. A source can be an object with keys that match the names of the {@link Adapter} state change functions, with a corresponding source or array of
  sources that trigger the store's reaction with the payload.

  #### Example: Object of sources

  ```typescript
  export class MyService {
    nameChange$ = new Source<string>('nameChange$');
    nameReset$ = new Source<void>('nameReset$');

    name = adapt('John', {
      sources: {
        set: this.nameChange$,
        reset: this.nameReset$,
      },
      path: 'name',
    });

    constructor() {
      this.name.state$.subscribe(console.log); // Logs 'John'
      this.nameChange$.next('Johnsh'); // logs 'Johnsh'
      this.nameReset$.next(); // logs 'John'
    }
  }
  ```

  4\. A source can be a function that takes in a detached store (result of calling {@link watch}) and returns any of the above
  types of sources.

  #### Example: Function that returns a source

  ```typescript
  export class MyService {
    name = adapt('John', {
      sources: store => store.state$.pipe(
        delay(1000),
        map(name => `${name}sh`),
        toSource('recursive nameChange$'),
      ),
      path: 'name',
    });

    constructor() {
      this.name.state$.subscribe(console.log); // Logs 'John'
      // logs 'Johnsh' after 1 second, then 'Johnshsh' after 2 seconds, etc.
    }
  }
  ```

  Defining a path alongside sources is recommended to enable debugging with Redux DevTools. It's easy to trace
  singular state changes caused by user events, but it's much harder to trace state changes caused by RxJS streams.

  The path string specifies the location in the global store you will find the state for the store being created
  (while the store has subscribers). StateAdapt splits this string at periods `'.'` to create an object path within
  the global store. Here are some example paths and the resulting global state objects:

  #### Example: Paths and global state

  ```typescript
  export class MyComponent {
    store = adapt(0, { path: 'number' });

    constructor() {
      this.store.state$.subscribe();
      // global state: { number: 0 }
    }
  }
  ```

  ```typescript
  export class MyComponent {
    store = adapt(0, { path: 'featureA.number' });

    constructor() {
      this.store.state$.subscribe();
      // global state: { featureA: { number: 0 } }
    }
  }
  ```

  ```typescript
  export class MyComponent {
    store = adapt(0, { path: 'featureA.featureB.number' });

    constructor() {
      this.store.state$.subscribe();
      // global state: { featureA: { featureB: { number: 0 } } }
    }
  }
  ```

  Each store completely owns its own state. If more than one store tries to use the same path, StateAdapt will throw this error:

  `Path '${path}' collides with '${existingPath}', which has already been initialized as a state path.`

  This applies both to paths that are identical as well as paths that are subtrings of each other. For example, if `'featureA'`
  is already being used by a store and then another store tried to initialize at `'featureA.number'`, that error would be thrown.

  To help avoid this error, StateAdapt provides a {@link getId} function that can be used to generate unique paths:

  #### Example: getId for unique paths

  ```typescript
  import { getId } from '@state-adapt/core';

  export class MyService {
    store1 = adapt(0, { path: 'number' + getId() });
    store2 = adapt(0, { path: 'number' + getId() });

    constructor() {
      this.store1.state$.subscribe();
      this.store2.state$.subscribe();
      // global state includes both: { number0: 0, number1: 0 }
    }
  }
  ```

  ### Remember!

  The store needs to have subscribers in order to start managing state.
  */
const adapt = (...args) => {
  const adaptDep = inject(StateAdapt);
  return adaptDep.adapt(...args);
};

// Differences between StateAdapt.watch and watch jsdoc:
//  - The phrase "`watch` wraps {@link StateAdapt.watch}, calling `inject(StateAdapt)` to get the instance of {@link StateAdapt} to use."
//  - Examples have been modified to show usage in classes
/**
   ## ![StateAdapt](https://miro.medium.com/max/4800/1*qgM6mFM2Qj6woo5YxDMSrA.webp|width=14) `watch`

   > Copilot tip: Copy examples into your file or click to definition to open file with context for better Copilot suggestions.

  `watch` wraps {@link StateAdapt.watch}, calling `inject(StateAdapt)` to get the instance of {@link StateAdapt} to use.

  `watch` returns a detached store (doesn't chain off of sources). This allows you to watch state without affecting anything.
  It takes 2 arguments: The path of the state you are interested in, and the adapter containing the selectors you want to use.

  ```tsx
  watch(path, adapter)
  ```

  path — Object path in Redux Devtools

  adapter — Object with state change functions and selectors

  ### Usage

  `watch` enables accessing state without subscribing to sources. For example, if your adapter manages the loading state
  for an HTTP request and you need to know if the request is loading before the user is interested in the data,
  `watch` can give you access to it without triggering the request.

  #### Example: Accessing loading state

  ```tsx
  watch('data', httpAdapter).loading$.subscribe(console.log);
  ```
  */
const watch = (...args) => {
  const adaptDep = inject(StateAdapt);
  return adaptDep.watch(...args);
};

/**
  ## ![StateAdapt](https://miro.medium.com/max/4800/1*qgM6mFM2Qj6woo5YxDMSrA.webp|width=14) `adaptInjectable`

  @deprecated Use a service instead. This was an experimental pattern and will be removed in the future.
  Also see ngxtensions: https://github.com/nartc/ngxtension-platform/blob/main/libs/ngxtension/create-injection-token/src/create-injection-token.ts

  Wraps {@link adapt} and creates an injectable
  Can be called outside of a service or component

  ### Example: Basic adaptInjectable

  ```typescript
  export const injectNameStore = adaptInjectable('John');

  // ...
  export class AppComponent {
    name$ = injectNameStore().state$;
  }
  ```

  ### Example: adaptInjectable using dependency injection

  ```typescript
  export const injectNameStore = adaptInjectable('John', {
    sources: () => {
      // Can inject dependencies here
      const name$ = inject(HttpClient).get<Name>('api/name');
      return name$.pipe(toSource('name$'));
    },
  });

  // ...
  export class AppComponent {
    name$ = injectNameStore().state$;
  }
  ```
  */
function adaptInjectable(initialState, second = {}) {
  const path = second?.path || 'adaptInjectable' + getId();
  const token = new InjectionToken(path, {
    providedIn: 'root',
    factory: function adaptInjectableFactory() {
      const adaptDep = inject(StateAdapt);
      const store = adaptDep.adapt(initialState, second);
      return store;
    }
  });
  return () => inject(token);
}

/**
  This will be replaced by the cheat sheet aggregator after the build.
  scripts/aggregate-cheat-sheet.js
*/
const angularCheatSheet = {};

/**
 * Generated bundle index. Do not edit.
 */

export { adapt, adaptInjectable, angularCheatSheet, defaultStoreProvider, provideStore, watch };
