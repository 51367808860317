import { Subject, of, concat, merge, using, isObservable, defer, NEVER, BehaviorSubject } from 'rxjs';
import { map, catchError, share, filter, distinctUntilChanged, tap, finalize, startWith, pairwise } from 'rxjs/operators';
import { getAction, prefixAction, createSelectorsCache, memoizeWithProxy, combineSelectors, getId, createAdapter, createNoopReaction, createUpdateReaction, getMemoizedSelector, flatten, createPatchState, createInit, createDestroy, globalSelectorsCache, globalSelectorsOptions, createAdaptNestedReducer, adaptReducer, actionSanitizer, stateSanitizer } from '@state-adapt/core';
const SubjectWithoutNext = Subject;
/**
  ## ![StateAdapt](https://miro.medium.com/max/4800/1*qgM6mFM2Qj6woo5YxDMSrA.webp|width=14) `Source`

  `Source` extends RxJS' [Subject](https://rxjs.dev/guide/subject) with an extra `type: string` property, and is used to create a stream of {@link Action} objects.
  When creating a source, you must provide a `type` argument, which will be the `type` property of the {@link Action} objects that will be emitted, and which will
  appear as the action type in Redux DevTools:

  ![Action Type in Redux Devtools](https://state-adapt.github.io/assets/devtools-add$.png)

  #### Example: Creating a source

  ```typescript
  import { Source } from '@state-adapt/rxjs';

  const add$ = new Source<number>('add$');

  add$.subscribe(action => console.log(action));
  add$.next(1);
  // { type: 'add$', payload: 1 }
  ```
 */
class Source extends SubjectWithoutNext {
  constructor(type) {
    super();
    this.type = type;
  }
  next(payload) {
    Subject.prototype.next.call(this, {
      type: this.type,
      payload
    });
  }
}

/**
  ## ![StateAdapt](https://miro.medium.com/max/4800/1*qgM6mFM2Qj6woo5YxDMSrA.webp|width=14) `toSource`

  `toSource` is a custom RxJS [operator](https://rxjs.dev/guide/operators) that converts an RxJS [Observable](https://rxjs.dev/guide/observable)
  of values of type {@link Payload} (inferred) into an observable of values of type {@link Action}<{@link Payload}, {@link Type}>.
  It takes one argument, `type`, which is the `type` property of the {@link Action} objects that will be emitted, and which will
  appear as the action type in Redux DevTools:

  ![Action Type in Redux Devtools](https://state-adapt.github.io/assets/devtools-timer$.png)

  #### Example: Converting an observable into a source

  ```typescript
  import { timer } from 'rxjs';
  import { toSource } from '@state-adapt/rxjs';

  const timer$ = timer(1000).pipe(toSource('timer$'));

  timer$.subscribe(console.log);
  // { type: 'timer$', payload: 0 }
  ```
 */
function toSource(type) {
  return source$ => source$.pipe(map(payload => ({
    type,
    payload
  })));
}

/**
  ## ![StateAdapt](https://miro.medium.com/max/4800/1*qgM6mFM2Qj6woo5YxDMSrA.webp|width=14) `catchErrorSource`

  `catchErrorSource` is a custom RxJS [operator](https://rxjs.dev/guide/operators) that converts an RxJS [Observable](https://rxjs.dev/guide/observable)
  of any values into a source of errors, using RxJS' [catchError](https://rxjs.dev/api/operators/catchError) operator.
  It takes one argument, {@link TypePrefix}, and prefixes it to create an object of type {@link Action}<any, \`${{@link TypePrefix}}.error$\`>.

  #### Example: Catching errors from a source

  ```typescript
  import { timer, map } from 'rxjs';
  import { toSource } from '@state-adapt/rxjs';

  const timer$ = timer(1000).pipe(
    map(n => n.fakeNumberMethod()),
    toSource('timer$'),
    catchErrorSource('timer'),
  );

  timer$.subscribe(console.log);
  // { type: 'timer.error$', payload: 'Error: n.fakeNumberMethod is not a function' }
  ```
 */
function catchErrorSource(typePrefix) {
  return source$ => source$.pipe(catchError(err => of(getAction(`${typePrefix}.error$`, err))));
}

/**
  ## ![StateAdapt](https://miro.medium.com/max/4800/1*qgM6mFM2Qj6woo5YxDMSrA.webp|width=14) `toRequestSource`

  `toRequestSource` combines the functionality of {@link toSource} and {@link catchErrorSource} into a single [operator](https://rxjs.dev/guide/operators).

  `toRequestSource` converts an RxJS [Observable](https://rxjs.dev/guide/observable)
  of values of type {@link Payload} (inferred) into an observable of values of type {@link Action}<{@link Payload}, \`${{@link TypePrefix}.success$}\`> | {@link Action}<any, \`${{@link TypePrefix}}.error$\`>.
  It takes one argument, `typePrefix`, which is the prefix of the `type` property of the {@link Action} objects that will be emitted.

  For the actions emitted without an error, the `type` property will be \`${{@link TypePrefix}}.success$}\` and the `payload` property will be the value emitted by the source observable.

  For the actions emitted with an error, the `type` property will be \`${{@link TypePrefix}}.error$}\` and the `payload` property will be the error object.

  #### Example: Converting an observable into a request source

  ```typescript
  import { interval } from 'rxjs';
  import { toRequestSource } from '@state-adapt/rxjs';

  const interval$ = interval(1000).pipe(
    map(n => n < 2 ? n : n.fakeNumberMethod()),
    toRequestSource('interval'),
  );

  interval$.subscribe(console.log);
  // { type: 'interval.success$', payload: 0 }
  // { type: 'interval.success$', payload: 1 }
  // { type: 'interval.error$', payload: 'Error: n.fakeNumberMethod is not a function' }
  ```
 */
function toRequestSource(typePrefix) {
  return source$ => source$.pipe(toSource(`${typePrefix}.success$`), catchErrorSource(typePrefix));
}

/**
  ## ![StateAdapt](https://miro.medium.com/max/4800/1*qgM6mFM2Qj6woo5YxDMSrA.webp|width=14) `splitSources`

  `splitSources` is a function that takes in a {@link Source} that emits many kinds of actions and splits it into multiple sources.
  It takes two arguments:
  - `source$`: [Observable](https://rxjs.dev/guide/observable)<{@link SharedAction}>
  - `partitions`: {@link PartitionKeys} extends { [index: string]: {@link SharedType} } — An object with keys that will become the new source names, and values that will filter against the `type` property of the actions from the `source$` observable.

  It returns an object with keys from the `partitions` argument and values of type [Observable](https://rxjs.dev/guide/observable)<{@link Action}<`Payload`, `Type`>> where `Payload` and `Type` are inferred from
  the filtered `SharedAction` type.

  #### Example: Splitting a source into multiple sources

  ```typescript
  import { getAction } from '@state-adapt/core';
  import { splitSources } from '@state-adapt/rxjs';
  import { interval, map } from 'rxjs';

  const evenAndOdd$ = interval(1000).pipe(map(n => {
    const type = n % 2 === 0 ? 'even$' : 'odd$';
    return getAction(type, n);
  }));

  eventAndOdd$.subscribe(console.log);
  // { type: 'even$', payload: 0 }
  // { type: 'odd$', payload: 1 }
  // { type: 'even$', payload: 2 }
  // { type: 'odd$', payload: 3 }

  const { even$, odd$ } = splitSources(evenAndOdd$, {
    even$: 'even$',
    odd$: 'odd$',
  });

  even$.subscribe(console.log);
  // { type: 'even$', payload: 0 }
  // { type: 'even$', payload: 2 }

 odd$.subscribe(console.log);
  // { type: 'odd$', payload: 1 }
  // { type: 'odd$', payload: 3 }
  ```

  #### Example: Splitting an HTTP source into success$ and error$ sources

  ```typescript
  import { getAction } from '@state-adapt/core';
  import { toSource, splitSources } from '@state-adapt/rxjs';
  import { ajax } from 'rxjs/ajax';

  const http$ = ajax('https://jsonplaceholder.typicode.com/todos/1').pipe(
    toSource('success$'),
    catchError(error => of(getAction('error$', error))),
  );

  const { success$, error$ } = splitSources(http$, {
    success$: 'success$',
    error$: 'error$',
  });

  success$.subscribe(console.log);
  // { type: 'success$', payload: { ... } }

  error$.subscribe(console.log);
  // { type: 'error$', payload: { ... } }
  ```
 */
function splitSources(source$, partitions) {
  // TODO: Add overload that takes in an array instead of an object
  // TODO: Write tests for this function
  const shared$ = source$.pipe(share()); // Each and every filtered source would cause everything upstream to run for iteslf
  return Object.entries(partitions).reduce((sources, [name, type]) => ({
    ...sources,
    [name]: shared$.pipe(filter(val => val.type === type))
  }), {});
}

/**
  ## ![StateAdapt](https://miro.medium.com/max/4800/1*qgM6mFM2Qj6woo5YxDMSrA.webp|width=14) `splitRequestSources`

  `splitRequestSources` is a function that takes in the type of [Observable](https://rxjs.dev/guide/observable)
   returned by {@link toRequestSource}, and
  a prefix {@link TypePrefix} to look for in the {@link Action} type, and returns an
  object with two [Observables](https://rxjs-dev.firebaseapp.com/guide/observable) of {@link Action} objects:  `success$`, and `error$`.

  #### Example: Splitting an observable of request actions into success$ and error$ sources

  ```typescript
  import { interval } from 'rxjs';
  import { splitRequestSources } from '@state-adapt/rxjs';

  const interval$ = interval(1000).pipe(
    map(n => n < 2 ? n : n.fakeNumberMethod()),
    toRequestSource('interval'),
  );

  const { success$, error$ } = splitRequestSources('interval', interval$);

  success$.subscribe(console.log);
  // { type: 'interval.success$', payload: 0 }
  // { type: 'interval.success$', payload: 1 }

  error$.subscribe(console.log);
  // { type: 'interval.error$', payload: 'Error: n.fakeNumberMethod is not a function' }
  ```
  */
function splitRequestSources(typePrefix, obs$) {
  return splitSources(obs$, {
    success$: `${typePrefix}.success$`,
    error$: `${typePrefix}.error$`
  });
}

/**
  ## ![StateAdapt](https://miro.medium.com/max/4800/1*qgM6mFM2Qj6woo5YxDMSrA.webp|width=14) `getRequestSources`

  `getRequestSources` is a function that combines the functionality of {@link toRequestSource} and {@link splitRequestSources}.

  `getRequestSources` takes in an [Observable](https://rxjs.dev/guide/observable) and an {@link Action} type prefix
  {@link TypePrefix} and splits the observable into two sources
  that become available as properties on the returned object as `success$` and `error$`.

  #### Example: Converting an observable into success$ and error$ sources

  ```typescript
  import { interval } from 'rxjs';
  import { getRequestSources } from '@state-adapt/rxjs';

  const interval$ = interval(1000).pipe(map(n => n < 2 ? n : n.fakeNumberMethod()));

  const { success$, error$ } = getRequestSources('interval', interval$);

  success$.subscribe(console.log);
  // { type: 'interval.success$', payload: 0 }
  // { type: 'interval.success$', payload: 1 }

  error$.subscribe(console.log);
  // { type: 'interval.error$', payload: 'Error: n.fakeNumberMethod is not a function' }
  ```

  #### Example: Conveniently splitting an HTTP source into success$ and error$ sources

  ```typescript
  import { getAction } from '@state-adapt/core';
  import { toSource, getRequestSources } from '@state-adapt/rxjs';
  import { ajax } from 'rxjs/ajax';

  const http$ = ajax('https://jsonplaceholder.typicode.com/todos/1');

  const httpRequest = getRequestSources('http', http$);

  httpRequest.success$.subscribe(console.log);
  // { type: 'success$', payload: { ... } }

  httpRequest.error$.subscribe(console.log);
  // { type: 'error$', payload: { ... } }
  ```
 */
function getRequestSources(typePrefix, obs$) {
  const requestSources = obs$.pipe(toRequestSource(typePrefix));
  return splitRequestSources(typePrefix, requestSources);
}
function prefixSource(prefix) {
  return obs$ => obs$.pipe(map(action => prefixAction(prefix, action)));
}
function getHttpError(type, req) {
  return err => of(req !== undefined ? getAction(type, [err, req]) : getAction(type, err));
}
function getCatchHttpError(type, req) {
  return obs$ => req !== undefined ? obs$.pipe(catchError(getHttpError(type, req))) // catchError doesn't like union types
  : obs$.pipe(catchError(getHttpError(type)));
}
function getHttpActions(...[http$, getResponse, req]) {
  const x$ = concat(of(getAction('request$', req)), http$.pipe(map(res => {
    const [succeeded, body, err] = getResponse(res);
    return succeeded ? getAction(`success$`, body) : getAction(`error$`, [err, req]);
  }), getCatchHttpError('error$', req)));
  const y$ = concat(of(getAction('request$')), http$.pipe(map(res => {
    const [succeeded, body, err] = getResponse(res);
    return succeeded ? getAction(`success$`, body) : getAction(`error$`, err);
  }), getCatchHttpError('error$')));
  const z$ = req !== undefined ? x$ : y$;
  return z$; // Something weird happens with the merging of the types, but z$ looks fine
}

function splitHttpSources(feature, httpWithSources$) {
  const sources = splitSources(httpWithSources$, {
    request$: 'request$',
    success$: 'success$',
    error$: 'error$'
  });
  return {
    request$: sources.request$.pipe(prefixSource(feature)),
    success$: sources.success$.pipe(prefixSource(feature)),
    error$: sources.error$.pipe(prefixSource(feature))
  };
}
function getHttpSources(feature, http$, getResponse, req) {
  const httpWithSources$ = req !== undefined ? getHttpActions(http$, getResponse, req) : getHttpActions(http$, getResponse);
  return splitHttpSources(feature, httpWithSources$);
}

/**
  ## ![StateAdapt](https://miro.medium.com/max/4800/1*qgM6mFM2Qj6woo5YxDMSrA.webp|width=14) `joinStores`

  `joinStores` is a function that takes in a `StoreEntries extends { [index: string]: StoreLike }` object and returns a `StoreBuilder` function.
  The `StoreBuilder` function can be called again and again with more selector definitions, and finally with no arguments to create a store.

  `joinStores` syntax is identical to that of {@link joinAdapters} so that you can easily switch between the two.
  The difference is that `joinStores` can only define selectors, while `joinAdapters` can define both selectors and reactions.

  #### Example: Combining states from two stores

  ```typescript
  import { joinStores } from '@state-adapt/rxjs';
  import { adapt } from '../configure-state-adapt.ts';

  const store1 = adapt(1);
  const store2 = adapt(2);

  const joinedStore = joinStores({ store1, store2 })();

  joinedStore.state$.subscribe(console.log);
  // { store1: 1, store2: 2 }
  ```

  #### Example: Combining selectors from two stores

  ```typescript
  import { createAdapter } from '@state-adapt/core';
  import { joinStores } from '@state-adapt/rxjs';
  import { adapt } from '../configure-state-adapt.ts';

  const adapter = createAdapter<number>()({
    selectors: {
      double: s => s * 2,
    }
  });

  const store1 = adapt(1, adapter);
  const store2 = adapt(2, adapter);

  const joinedStore = joinStores({ store1, store2 })({
    sum: s => s.store1Double + s.store2Double,
  })();

  joinedStore.sum$.subscribe(console.log);
  // 6
 */
// state => easy
// joined selectors => Map—Grab from each store, prefix object, do merge trick
//
function joinStores(storeEntries) {
  // The initial selectors defined in `combineSelectors` are selecting against State.
  // Here, we are defining the initial selectors. The result includes
  // a selector for each piece of state. The developer only defines the 2nd+ selector group.
  const namespaces = Object.keys(storeEntries);
  const joinedSelectors = {};
  const joinedFullSelectors = {};
  const selectorsCache = createSelectorsCache();
  // const fullSelectorsCache = createSelectorsCache();
  const getCacheOverride = sharedChildCache => {
    // Use this join's cache instead of the one from future joins
    // But register future joins' caches as children
    // Skip if the child cache is the same as the parent cache—it's a selector from this joinStores call
    if (sharedChildCache && sharedChildCache !== selectorsCache) {
      selectorsCache.__children[sharedChildCache.__id] = sharedChildCache;
    }
    return selectorsCache;
  };
  // const getCacheOverride = createGetCacheOverride(selectorsCache);
  // const getFullCacheOverride = createGetCacheOverride(fullSelectorsCache);
  const getJoinedState = state => {
    const newState = {};
    namespaces.forEach(key => {
      newState[key] = state[key];
    });
    return newState;
  };
  joinedSelectors['state'] = memoizeWithProxy()('state', joinedSelectors, getJoinedState, getCacheOverride);
  joinedFullSelectors['state'] = memoizeWithProxy()('state', joinedFullSelectors, getJoinedState, getCacheOverride);
  const joinedInitialState = {};
  namespaces.forEach(namespace => {
    // Already cached selectors to be made available under new names
    // to be used in subsequent selector definitions.
    joinedInitialState[namespace] = storeEntries[namespace].__.initialState;
    const selectors = storeEntries[namespace].__.selectors;
    const fullSelectors = storeEntries[namespace].__.fullSelectors;
    for (const selectorName in fullSelectors) {
      const selector = selectors[selectorName];
      const fullSelector = fullSelectors[selectorName];
      if (selectorName === 'state') {
        joinedSelectors[namespace] = state => state[namespace];
        joinedFullSelectors[namespace] = fullSelector;
      } else {
        const newSelectorName = `${namespace}${selectorName.charAt(0).toUpperCase() + selectorName.substr(1)}`;
        joinedSelectors[newSelectorName] = memoizeWithProxy()(newSelectorName, joinedSelectors, s => selector(s[namespace]),
        // selector is already cached
        getCacheOverride);
        joinedFullSelectors[newSelectorName] = fullSelector;
      }
    }
  });
  return addNewBlock({
    storeEntries,
    namespaces,
    selectors: joinedSelectors,
    fullSelectors: joinedFullSelectors,
    initialState: joinedInitialState,
    getCacheOverride
  });
}
function addNewBlock(builder) {
  return newS => {
    if (newS) return addNewBlock({
      ...builder,
      fullSelectors: combineSelectors()(builder.fullSelectors, newS,
      // These new selectors will ignore the cache objects (if any) given to them
      // Instead, attach that cache object as a child of this joined store's cache object
      builder.getCacheOverride),
      selectors: combineSelectors()(builder.selectors, newS,
      // These new selectors will ignore the cache objects (if any) given to them
      // Instead, attach that cache object as a child of this joined store's cache object
      builder.getCacheOverride)
    });
    // Done
    const {
      namespaces,
      selectors,
      fullSelectors,
      initialState,
      storeEntries
    } = builder;
    const select = storeEntries[namespaces[0]].__.select;
    const requireAllSources$ = merge(...namespaces.map(namespace => storeEntries[namespace].__.requireSources$));
    const joinedStore = {
      __: {
        selectors,
        fullSelectors,
        requireSources$: requireAllSources$,
        initialState,
        select: select
      }
    };
    for (const selectorName in fullSelectors) {
      joinedStore[selectorName + '$'] = using(() => requireAllSources$.subscribe(), () => select(fullSelectors[selectorName]));
    }
    return joinedStore;
  };
}
function isSource(thing) {
  return Array.isArray(thing) || isObservable(thing);
}
function isAdaptOptions(options) {
  return ['path', 'adapter', 'sources'].some(key => key in (options || {}));
}
const filterDefined = sel$ => sel$.pipe(filter(a => a !== undefined), distinctUntilChanged());
class StateAdapt {
  constructor(commonStore) {
    this.commonStore = commonStore;
    this.pathStates = {};
    this.updaterStreams = [];
  }
  /**
  ## ![StateAdapt](https://miro.medium.com/max/4800/1*qgM6mFM2Qj6woo5YxDMSrA.webp|width=14) `StateAdapt.adapt`
     > Copilot tip: Copy examples into your file or click to definition to open file with context for better Copilot suggestions.
     `adapt` creates a store that will manage state while it has subscribers.
     ### Example: initialState only
  `adapt(initialState)`
     The simplest way to use `adapt` is to only pass it an initial state. `adapt` returns a store object that is ready to start managing state once it has subscribers.
  The store object comes with `set` and `reset` methods for updating state, and a `state$` observable of the store's state.
     ```typescript
  const name = adapt('John');
     name.state$.subscribe(console.log); // logs 'John'
     name.set('Johnsh'); // logs 'Johnsh'
  name.reset(); // logs 'John'
  ```
     Usually you won't manually subscribe to state like this, but you can if you want the store to immediately start managing state
  and never clean it up.
     ### Example: Using an adapter
  `adapt(initialState, adapter)`
     You can also pass in a state {@link Adapter} object to customize the state change functions and selectors.
     ```typescript
  const name = adapt('John', {
    concat: (state, payload: string) => state + payload,
    selectors: {
      length: state => state.length,
    },
  });
     name.state$.subscribe(console.log); // Logs 'John'
  name.length$.subscribe(console.log); // Logs 4
     name.concat('sh'); // logs 'Johnsh' and 6
  name.reset(); // logs 'John' and 4
  ```
     ### Example: Using {@link AdaptOptions}
  `adapt(initialState, { adapter, sources, path })`
     You can also define an adapter, sources, and/or a state path as part of an {@link AdaptOptions} object.
     Sources allow the store to declaratively react to external events rather than being commanded
  by imperative callback functions.
     ```typescript
  const tick$ = interval(1000).pipe(toSource('tick$'));
     const clock = adapt(0, {
    adapter: {
      increment: state => state + 1,
    },
    sources: tick$, // or [tick$], or { set: tick$ }, or { set: [tick$] }
    path: 'clock',
  });
     clock.state$.subscribe(console.log); // Logs 0, 1, 2, 3, etc.
  ```
     There are 4 possible ways sources can be defined:
     1\. A source can be a single {@link Source} or [Observable](https://rxjs.dev/guide/observable)<{@link Action}<{@link State}>>. When the source emits, it triggers the store's `set` method
  with the payload.
     #### Example: Single source
     ```typescript
  const nameChange$ = new Source<string>('nameChange$');
     const name = adapt('John', {
    sources: nameChange$,
    path: 'name',
  });
     name.state$.subscribe(console.log); // Logs 'John'
     nameChange$.next('Johnsh'); // logs 'Johnsh'
  ```
     2\. A source can be an array of {@link Source} or [Observable](https://rxjs.dev/guide/observable)<{@link Action}<{@link State}>>. When any of the sources emit, it triggers the store's `set`
   method with the payload.
     #### Example: Array of sources
     ```typescript
  const nameChange$ = new Source<string>('nameChange$');
  const nameChange2$ = new Source<string>('nameChange2$');
     const name = adapt('John', {
    sources: [nameChange$, nameChange2$],
    path: 'name',
  });
     name.state$.subscribe(console.log); // Logs 'John'
     nameChange$.next('Johnsh'); // logs 'Johnsh'
  nameChange2$.next('Johnsh2'); // logs 'Johnsh2'
  ```
     3\. A source can be an object with keys that match the names of the {@link Adapter} state change functions, with a corresponding source or array of
  sources that trigger the store's reaction with the payload.
     #### Example: Object of sources
     ```typescript
  const nameChange$ = new Source<string>('nameChange$');
  const nameReset$ = new Source<void>('nameReset$');
     const name = adapt('John', {
    sources: {
      set: nameChange$,
      reset: nameReset$,
    },
    path: 'name',
  });
     name.state$.subscribe(console.log); // Logs 'John'
     nameChange$.next('Johnsh'); // logs 'Johnsh'
  nameReset$.next(); // logs 'John'
  ```
     4\. A source can be a function that takes in a detached store (result of calling {@link StateAdapt.watch}) and returns any of the above
  types of sources.
     #### Example: Function that returns a source
     ```typescript
  const name = adapt('John', {
    sources: store => store.state$.pipe(
      delay(1000),
      map(name => `${name}sh`),
      toSource('recursive nameChange$'),
    ),
    path: 'name',
  });
     name.state$.subscribe(console.log); // Logs 'John'
  // logs 'Johnsh' after 1 second, then 'Johnshsh' after 2 seconds, etc.
  ```
     Defining a path alongside sources is recommended to enable debugging with Redux DevTools. It's easy to trace
  singular state changes caused by user events, but it's much harder to trace state changes caused by RxJS streams.
     The path string specifies the location in the global store you will find the state for the store being created
  (while the store has subscribers). StateAdapt splits this string at periods `'.'` to create an object path within
  the global store. Here are some example paths and the resulting global state objects:
     #### Example: Paths and global state
     ```typescript
  const store = adapt(0, { path: 'number' });
  store.state$.subscribe();
  // global state: { number: 0 }
  ```
     ```typescript
  const store = adapt(0, { path: 'featureA.number' });
  store.state$.subscribe();
  // global state: { featureA: { number: 0 } }
  ```
     ```typescript
  const store = adapt(0, { path: 'featureA.featureB.number' });
  store.state$.subscribe();
  // global state: { featureA: { featureB: { number: 0 } } }
  ```
     Each store completely owns its own state. If more than one store tries to use the same path, StateAdapt will throw this error:
     `Path '${path}' collides with '${existingPath}', which has already been initialized as a state path.`
     This applies both to paths that are identical as well as paths that are subtrings of each other. For example, if `'featureA'`
  is already being used by a store and then another store tried to initialize at `'featureA.number'`, that error would be thrown.
     To help avoid this error, StateAdapt provides a {@link getId} function that can be used to generate unique paths:
     #### Example: getId for unique paths
     ```typescript
  import { getId } from '@state-adapt/core';
     const store1 = adapt(0, { path: 'number' + getId() });
  store1.state$.subscribe();
  const store2 = adapt(0, { path: 'number' + getId() });
  store2.state$.subscribe();
  // global state includes both: { number0: 0, number1: 0 }
  ```
     ### Remember!
     The store needs to have subscribers in order to start managing state.
  */
  adapt(initialState, second = {}) {
    let sources;
    // Initialize parameters
    const options = isAdaptOptions(second) ? second : undefined;
    const path = options?.path || getId().toString();
    // const stackItems = {
    //   Error: false,
    //   'at StateAdapt.adapt': false,
    //   'at adapt': false,
    //   'at Object.adaptInjectableFactory [as factory]': 'adaptInjectableFactory',
    // } as const;
    // const stackLines = new Error().stack
    //   ?.split('\n')
    //   .map(line => line.trim().split(' (http')[0]);
    // const interestingLine = stackLines?.find(
    //   line => (stackItems[line as keyof typeof stackItems] as any) !== false,
    // );
    // const stackPath =
    //   stackItems[interestingLine as keyof typeof stackItems] || interestingLine;
    // console.log(interestingLine);
    // const path =
    //   options?.path ||
    //   this.adapt.caller.toString().replace(/\./g, '_') + getId().toString();
    const adapterArg = options?.adapter || second;
    const adapter = adapterArg ? createAdapter()(adapterArg) : createAdapter()({});
    adapter.noop = createNoopReaction();
    if (!adapter.update && typeof initialState === 'object' && !Array.isArray(initialState) && initialState !== null) {
      adapter.update = createUpdateReaction();
    }
    const sourcesArg = options?.sources;
    const sourcesDefined = typeof sourcesArg === 'function' ? sourcesArg(this.watch(path, adapter)) : sourcesArg || {};
    sources = isSource(sourcesDefined) // Single source or array
    ? {
      set: sourcesDefined
    } : sourcesDefined;
    sources = sources;
    // Parameters are all defined
    const pathObj = this.parsePath(path);
    const [requireSources$, syntheticSources] = this.getRequireSources(adapter, pathObj, sources, initialState);
    const getSelectorsCache = this.getSelectorsCacheFactory(path);
    const getState = this.getStateSelector(pathObj.pathAr, initialState);
    const getStateSelector = getMemoizedSelector(path, getState, () => this.getGlobalSelectorsCache()); // all state selectors go in global cache
    const {
      fullSelectors,
      selections,
      selectors
    } = this.getSelections(adapter.selectors, getStateSelector, requireSources$, getSelectorsCache);
    return {
      ...syntheticSources,
      ...selections,
      __: {
        requireSources$,
        selectors,
        fullSelectors,
        initialState,
        path,
        select: sel => filterDefined(this.commonStore.select(sel))
      }
    };
  }
  /**
   ## ![StateAdapt](https://miro.medium.com/max/4800/1*qgM6mFM2Qj6woo5YxDMSrA.webp|width=14) `StateAdapt.watch`
      > Copilot tip: Copy examples into your file or click to definition to open file with context for better Copilot suggestions.
     `watch` returns a detached store (doesn't chain off of sources). This allows you to watch state without affecting anything.
  It takes 2 arguments: The path of the state you are interested in, and the adapter containing the selectors you want to use.
     ```tsx
  watch(path, adapter)
  ```
     path — Object path in Redux Devtools
     adapter — Object with state change functions and selectors
     ### Usage
     `watch` enables accessing state without subscribing to sources. For example, if your adapter manages the loading state
  for an HTTP request and you need to know if the request is loading before the user is interested in the data,
  `watch` can give you access to it without triggering the request.
     #### Example: Accessing loading state
     ```tsx
  watch('data', httpAdapter).loading$.subscribe(console.log);
  ```
   */
  watch(path, adapter) {
    const adapterSelectors = adapter.selectors || {};
    const getSelectorsCache = this.getSelectorsCacheFactory(path);
    const getState = this.getStateSelector(path.split('.'));
    const getStateSelector = getMemoizedSelector(path, getState, () => this.getGlobalSelectorsCache()); // all state selectors go in global cache
    const requireSources$ = of(null);
    const {
      fullSelectors,
      selections,
      selectors
    } = this.getSelections(adapterSelectors, getStateSelector, requireSources$, getSelectorsCache);
    return {
      ...selections,
      __: {
        requireSources$: requireSources$,
        fullSelectors: fullSelectors,
        selectors,
        initialState: undefined,
        path,
        select: sel => filterDefined(this.commonStore.select(sel))
      }
    };
  }
  parsePath(path) {
    return {
      path,
      pathAr: path.split('.')
    };
  }
  getRequireSources(reactions, {
    path,
    pathAr
  }, sources, initialState) {
    const reactionEntries = Object.entries(reactions);
    const allSourcesWithReactions = flatten(reactionEntries.filter(([name]) => name !== 'selectors').map(([reactionName, reaction]) => {
      const reactionSource = sources[reactionName] || [];
      const reactionSources = Array.isArray(reactionSource) ? reactionSource : [reactionSource];
      return reactionSources.map(source$ => ({
        source$,
        reaction
      }));
    }));
    const allUpdatesFromSources$ = allSourcesWithReactions.map(({
      source$,
      reaction
    }, i) => {
      // Source-grouped updates:
      return defer(() => {
        const updaterStream = this.getSourceUpdateStream(source$);
        const requireSources$ = updaterStream ? updaterStream.requireSources$ : source$.pipe(tap(action => {
          const updates = this.getAllSourceUpdates(source$, action);
          this.commonStore.dispatch(createPatchState(action, updates));
        }), finalize(() => {
          this.updaterStreams.splice(this.updaterStreams.findIndex(({
            source$: updaterSource$
          }) => source$ === updaterSource$), 1);
        }), share());
        if (!updaterStream) {
          this.updaterStreams.push({
            source$,
            requireSources$,
            reactions: []
          });
        }
        // Now there is definitely an update stream for this source, so push this reaction onto it
        const updateStream = this.getSourceUpdateStream(source$);
        updateStream?.reactions.push({
          path,
          reaction
        });
        return requireSources$;
      }).pipe(share());
    });
    const requireSources$ = defer(() => {
      // Runs first upon subscription.
      // If any of the sources emits immediately, this needs to have been set up first.
      const colllisionPath = this.getPathCollisions(path);
      if (colllisionPath) {
        throw this.getPathCollisionError(path, colllisionPath);
      }
      const selectorsCache = this.createSelectorsCache(path);
      this.commonStore.dispatch(createInit(path, initialState));
      this.pathStates[path] = {
        lastState: initialState,
        initialState,
        arr: pathAr,
        selectorsCache
      };
      return merge(...allUpdatesFromSources$, NEVER); // If sources all complete, keep state in the store
    }).pipe(finalize(() => {
      // Runs Last to clean up the store:
      allSourcesWithReactions.forEach(({
        source$
      }) => {
        const updateStream = this.getSourceUpdateStream(source$);
        const updateReactions = updateStream?.reactions || [];
        updateReactions.splice(updateReactions.findIndex(({
          path: reactionPath
        }) => reactionPath === path), 1);
      });
      delete this.pathStates[path];
      this.destroySelectorsCache(path);
      this.commonStore.dispatch(createDestroy(path));
    }), share());
    const syntheticSources = reactionEntries.reduce((acc, [reactionName, reaction]) => {
      return {
        ...acc,
        [reactionName]: payload => {
          const update = this.getUpdate(path, reaction, payload);
          const action = getAction(`${path} ${reactionName}`, payload);
          this.commonStore.dispatch(createPatchState(action, [update]));
        }
      };
    }, {});
    return [requireSources$, syntheticSources];
  }
  getPathCollisions(path) {
    return Object.keys(this.pathStates).find(existingPath => path === existingPath || existingPath + '.' === path.substr(0, existingPath.length + 1) || path + '.' === existingPath.substr(0, path.length + 1));
  }
  getPathCollisionError(path, existingPath) {
    return new Error(`Path '${path}' collides with '${existingPath}', which has already been initialized as a state path.`);
  }
  getSourceUpdateStream(searchSource$) {
    return this.updaterStreams.find(({
      source$
    }) => searchSource$ === source$);
  }
  getAllSourceUpdates(source$, {
    payload
  }) {
    return this.getSourceUpdateStream(source$).reactions.map(({
      path,
      reaction
    }) => this.getUpdate(path, reaction, payload));
  }
  getUpdate(path, reaction, payload) {
    const pathState = this.pathStates[path];
    if (pathState === undefined) {
      throw `Cannot apply update before store "${path}" is initialized.`;
    }
    const {
      lastState,
      initialState,
      arr,
      selectorsCache
    } = pathState;
    const newState = reaction(lastState, payload, initialState, selectorsCache);
    pathState.lastState = newState;
    return [arr, newState];
  }
  getStateSelector(pathAr, initialState) {
    return ({
      adapt
    }) => {
      const pathState = pathAr.reduce((state, segment) => state?.[segment], adapt);
      return pathState === undefined ? initialState : pathState;
    };
  }
  getGlobalSelectorsCache() {
    return globalSelectorsCache;
  }
  getSelectorsCacheFactory(path) {
    return () => globalSelectorsCache.__children[path];
  }
  createSelectorsCache(path) {
    return globalSelectorsCache.__children[path] = createSelectorsCache();
  }
  destroySelectorsCache(path) {
    delete globalSelectorsCache.__children[path];
  }
  getSelections(selectors, getStateSelector,
  // uses global cache
  requireSources$, getSelectorsCache) {
    const getUsing = selection$ => using(() => requireSources$.subscribe(), () => filterDefined(selection$));
    const selections = {
      fullSelectors: {
        state: getStateSelector
      },
      selections: {
        state$: getUsing(this.commonStore.select(getStateSelector))
      },
      selectors: {
        state: pathState => pathState
      }
    };
    for (const key in selectors) {
      const fullSelector = (state, sharedChildCache) => {
        const pathState = getStateSelector(state);
        if (pathState !== undefined) {
          const cache = getSelectorsCache();
          if (cache && sharedChildCache) {
            cache && (cache.__children[sharedChildCache.__id] = sharedChildCache);
          }
          return selectors[key](pathState, cache);
        }
      };
      selections.selectors[key] = pathState => selectors[key](pathState, getSelectorsCache());
      selections.fullSelectors[key] = fullSelector;
      selections.selections[key + '$'] = getUsing(this.commonStore.select(fullSelector));
    }
    return selections;
  }
}
class ObservableStore extends BehaviorSubject {
  constructor(store) {
    super(store.getState());
    this.actionQueue = []; // First is currently dispatching
    this.store = store;
    store.subscribe(() => this.next(store.getState()));
  }
  select(sel) {
    return this.pipe(filter(state => state !== undefined), map(state => sel(state)), filter(state => state !== undefined), distinctUntilChanged());
  }
  dispatch(action) {
    if (!this.actionQueue[0]) {
      this.actionQueue.push(action);
      this.dispatchNext();
    } else {
      this.actionQueue.push(action);
    }
  }
  dispatchNext() {
    if (this.actionQueue[0]) {
      this.store.dispatch(this.actionQueue[0]);
      this.actionQueue.shift();
      this.dispatchNext();
    }
  }
}
function createGlobalStore(reducer, preloadedState, enhancer) {
  let state = preloadedState ?? undefined;
  const listeners = [];
  if (enhancer) {
    return enhancer(createGlobalStore)(reducer, state);
  }
  const dispatch = action => {
    state = reducer(state, action);
    listeners.forEach(l => l(state));
  };
  dispatch({
    type: '@@redux/INIT' + Math.random()
  });
  return {
    getState: () => state,
    dispatch,
    subscribe: cb => {
      listeners.push(cb);
      return () => listeners.splice(listeners.indexOf(cb), 1);
    }
  };
}
function isStoreOptions(options) {
  return options.store !== undefined;
}
// TODO: Write docs for angular, ngrx, ngxs, angular-router, react
// TODO: Write script that exports only the examples onto a cheat-sheet, directly into a `cheat-sheet.ts` file with
//   export function libNameExamples() { return { ... } }
/**
  ## ![StateAdapt](https://miro.medium.com/max/4800/1*qgM6mFM2Qj6woo5YxDMSrA.webp|width=14) `configureStateAdapt`

  > Copilot tip: Copy examples into your file or click to definition to open file with context for better Copilot suggestions.

  `configureStateAdapt` takes in a {@link ConfigureStateAdaptOptions} object and returns a new instance of {@link StateAdapt}.

  ### Example: Standalone with default options

  ```ts
  import { actionSanitizer, stateSanitizer } from '@state-adapt/core';
  import { configureStateAdapt } from '@state-adapt/rxjs';

  export const stateAdapt = configureStateAdapt();

  export const { adapt, watch } = stateAdapt;
  ```

  ### Example: Standalone

  ```ts
  import { actionSanitizer, stateSanitizer } from '@state-adapt/core';
  import { configureStateAdapt } from '@state-adapt/rxjs';

  export const stateAdapt = configureStateAdapt({
    devtools: (window as any)?.__REDUX_DEVTOOLS_EXTENSION__?.({
      actionSanitizer,
      stateSanitizer,
    }),
    showSelectors: false,
  });

  export const { adapt, watch } = stateAdapt;
  ```

  ### Example: With another store

  ```ts
  import { configureStore } from '@reduxjs/toolkit'; // or any other Redux-like store
  import { configureStateAdapt } from '@state-adapt/rxjs';
  import { reducer } from './reducer';

  const store = configureStore({ reducer });

  export const stateAdapt = configureStateAdapt({ store });

  export const { adapt, watch } = stateAdapt;
  ```
*/
function configureStateAdapt(options = {
  devtools: typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__?.({
    actionSanitizer,
    stateSanitizer
  })
}) {
  globalSelectorsOptions.devtools = options.showSelectors ?? true;
  const store = isStoreOptions(options) ? options.store : createGlobalStore(createAdaptNestedReducer(adaptReducer), options.preloadedState, options.devtools);
  const stateAdapt = new StateAdapt(new ObservableStore(store));
  return {
    adapt: stateAdapt.adapt.bind(stateAdapt),
    watch: stateAdapt.watch.bind(stateAdapt),
    ['commonStore']: stateAdapt.commonStore
  };
}
function mapEachWithEffect(input$, fn) {
  const cache = new Map();
  return input$.pipe(startWith([]), pairwise(), map(([prev, next]) => {
    prev.forEach(value => {
      if (!next.includes(value)) {
        const [_, destroy] = cache.get(value);
        destroy?.();
        cache.delete(value);
      }
    });
    return next.map(value => {
      if (!cache.has(value)) {
        const results = fn(value);
        cache.set(value, results);
        return results[0];
      } else {
        return cache.get(value)[0];
      }
    });
  }));
}

/**
  This will be replaced by the cheat sheet aggregator after the build.
  scripts/aggregate-cheat-sheet.js
*/
const rxjsCheatSheet = {};

/**
 * Generated bundle index. Do not edit.
 */

export { Source, StateAdapt, catchErrorSource, configureStateAdapt, getCatchHttpError, getHttpActions, getHttpError, getHttpSources, getRequestSources, isAdaptOptions, joinStores, mapEachWithEffect, prefixSource, rxjsCheatSheet, splitHttpSources, splitRequestSources, splitSources, toRequestSource, toSource };
