function expectType() {
  return _value => {
    return _value;
  };
}
let id = 0;
/**
  ## ![StateAdapt](https://miro.medium.com/max/4800/1*qgM6mFM2Qj6woo5YxDMSrA.webp|width=14) `getId`

  `getId` returns a unique id.
 */
function getId() {
  return id++;
}
function patch(updater) {
  return obj => ({
    ...obj,
    ...updater(obj)
  });
}
function updatePaths(oldState, updates) {
  let newValEntry;
  const nextLevelUpdatedState = updates.reduce((stateWithUpdates, [remainingPath, newVal]) => {
    const nextSegment = remainingPath[0] || '';
    const otherUpdatesForSegment = stateWithUpdates[nextSegment];
    // There can only be one empty remaining path at each level. That gets assigned to the '' property.
    if (!nextSegment) {
      newValEntry = [[], newVal];
      return stateWithUpdates;
    }
    return {
      ...stateWithUpdates,
      [nextSegment]: otherUpdatesForSegment ? [...otherUpdatesForSegment, [remainingPath.slice(1), newVal]] : [[remainingPath.slice(1), newVal]]
    };
  }, {});
  const wasObject = getIsObject(oldState);
  return newValEntry ? newValEntry[1] : Object.entries(nextLevelUpdatedState).reduce((state, [prop, childUpdates]) => ({
    ...(state || {}),
    [prop]: updatePaths((state || {})[prop] || {}, childUpdates)
  }), wasObject ? oldState : {});
}
function getIsObject(thing) {
  return typeof thing === 'object' && !Array.isArray(thing);
}
function flatten(arr) {
  return arr.reduce((flattened, ar) => {
    ar.forEach(el => flattened.push(el));
    return flattened;
  }, []);
}
function prefixAction(prefix, {
  type,
  payload
}) {
  return {
    type: `${prefix} ${type}`,
    payload
  };
}
function getAction(type, payload) {
  return {
    type,
    payload
  };
}
let cacheId = 0;
const createSelectorsCache = (children = {}) => {
  return {
    __id: cacheId++,
    __results: {},
    __inputs: {},
    __children: children
  };
};
const globalSelectorsOptions = {
  devtools: true
};
const globalSelectorsCache = createSelectorsCache();
const serializeSelectorsCache = c => {
  const results = {
    ...c.__results
  };
  const children = c.__children;
  for (const prop in children) {
    results[prop] = serializeSelectorsCache(children[prop]);
  }
  return results;
};
function memoizeSelectors(selectorDefinitions) {
  if (selectorDefinitions['state']) return selectorDefinitions; // Already went through this function
  const selectors = {
    state: s => s
  };
  for (const name in selectorDefinitions) {
    const fn = selectorDefinitions[name];
    selectors[name] = getMemoizedSelector(name, fn);
  }
  return selectors;
}
function getMemoizedSelector(name, fn, toChildCache) {
  return (s, parentCache) => {
    const cache = toChildCache ? toChildCache(parentCache) : parentCache;
    if (!cache) return fn(s);
    const {
      values
    } = cache.__inputs[name] = cache.__inputs[name] || {
      set: new Set(['state']),
      values: {
        state: undefined
      }
    };
    if (s === values['state']) return cache.__results[name]; // The only input "selector" returned the same thing
    values['state'] = s;
    return cache.__results[name] = fn(s, cache);
  };
}
function mapToSelectorsWithCache(selectors, getFeature, cache) {
  const definedCache = cache || createSelectorsCache();
  const newSelectors = {};
  for (const prop in selectors) {
    newSelectors[prop] = state => selectors[prop](getFeature(state), definedCache);
  }
  return newSelectors;
}

/**
 *
 * @returns original selectors object with new selectors added (mutates)
 */
function combineSelectors() {
  return (selectors, newSelectors = {}, getCacheOverride) => {
    for (const name in newSelectors) {
      selectors[name] = memoizeWithProxy()(name, selectors, newSelectors[name], getCacheOverride);
    }
    return selectors;
  };
}
function memoizeWithProxy() {
  return (name, selectors, fn, getCacheOverride) => {
    return (s, providedCache) => {
      const cache = getCacheOverride ? getCacheOverride(providedCache) : providedCache;
      // 1. No cache provided, just evaluate without memoization
      if (!cache) {
        const handler = {
          get: function (target, prop) {
            return target[prop](s);
          }
        };
        const proxy = new Proxy(selectors, handler);
        return fn(proxy);
      }
      const results = cache.__results;
      const cachedResult = results[name];
      // 2. Get cached inputs, return cachedResult if results of cached inputs are all the same
      const inputs = cache.__inputs;
      const cachedInputs = inputs[name] = inputs[name] || {
        set: new Set(),
        values: {}
      };
      const cachedInputsSet = cachedInputs.set;
      const cachedInputValues = cachedInputs.values;
      // If all registered inputs record the same results, the final result will be the same (selectors are deterministic)
      // On initial run, no cachedInputs; skip past this optimization so an input can be added to cachedInputs
      // This calls each registered input which may have a cached value
      const allInputResultsSame = !!cachedInputsSet.size && [...cachedInputsSet].every(inputName => {
        const previousInputValue = cachedInputValues[inputName];
        const newInputValue = cachedInputValues[inputName] = selectors[inputName](s, cache);
        return previousInputValue === newInputValue;
      });
      if (allInputResultsSame) return cachedResult;
      // 3. Recalculate
      // Pass proxy into fn to watch for additional input selectors being accessed
      const handler = {
        get: function (target, inputName) {
          cachedInputsSet.add(inputName);
          const newInputValue = cachedInputValues[inputName] = target[inputName](s, cache);
          return newInputValue;
        }
      };
      const proxy = new Proxy(selectors, handler);
      return results[name] = fn(proxy);
    };
  };
}
function createUpdateReaction() {
  return (state, update) => ({
    ...state,
    ...update
  });
}
function createNoopReaction() {
  return state => state;
}
function createReactions() {
  return getReactions => getReactions();
}

/**
  ## ![StateAdapt](https://miro.medium.com/max/4800/1*qgM6mFM2Qj6woo5YxDMSrA.webp|width=10) `createAdapter`

  `createAdapter` is a function that takes an {@link Adapter} object and returns a new {@link Adapter} object with the following state change functions added:
  - `set`: A reaction that sets the state to the payload
  - `reset`: A reaction that sets the state to the initial state

  Every adapter also comes with a default selector:

  - `state` returns the top-level state value

  #### Example: Empty initial adapter object

  ```typescript
  import { createAdapter } from '@state-adapt/core';

  const numberAdapter = createAdapter<number>()({});
  ```

  #### Example: Small initial adapter object

  ```typescript
  import { createAdapter } from '@state-adapt/core';

  const numberAdapter = createAdapter<number>()({
    add: (state, n: number) => state + n,
    subtract: (state, n: number) => state - n,
    selectors: {
      negative: state => state * -1,
    },
  });
  ```

  #### Example: Initial adapter object with complex state

  ```typescript
  import { createAdapter } from '@state-adapt/core';

  interface ComplexState {
    count: number;
    name: string;
  }

  const complexAdapter = createAdapter<ComplexState>()({
    increment: state => ({ ...state, count: state.count + 1 }),
    decrement: state => ({ ...state, count: state.count - 1 }),
    setName: (state, name: string) => ({ ...state, name }),
    selectors: {
      negative: state => state.count * -1,
    },
  });
  ```

*/
function createAdapter() {
  return adapter => ({
    set: (state, payload) => payload,
    // Add update reaction back if we ever pass in a default state
    reset: (state, payload, initialState) => initialState,
    ...adapter,
    selectors: memoizeSelectors(adapter.selectors || {}) // New selectors object
  });
}

/**
 * @deprecated Keep UI logic in the UI layer. Keep API logic in the sources layer. Keep the adapter layer for shareable state management logic, detachable from the UI and API layers.
 */
function mapPayloads(reactions, maps) {
  const newReactions = {};
  for (const prop in maps) {
    const map = maps[prop];
    const reaction = reactions[prop];
    newReactions[prop] = (state, payload, initialState) => reaction(state, map(payload), initialState);
  }
  return newReactions;
}

/**
  ## ![StateAdapt](https://miro.medium.com/max/4800/1*qgM6mFM2Qj6woo5YxDMSrA.webp|width=14) `buildAdapter`

  `buildAdapter` is called with an initial adapter, then can be called again and again with more objects inheriting from previous objects,
  until a final empty call `()` to get the final built adapter:

  ```typescript
  import { buildAdapter } from '@state-adapt/core';
  import { numberAdapter } from './number.adapter';

  const numberStringAdapter = buildAdapter<number>()(numberAdapter)({
    // Define more selectors
  })(([selectors, reactions]) => ({
    // Define more state changes
  }))({
    // Define grouped state changes
  })(); // End
  ```

  The first call creates a new object, but after that, every object passed in is looped over and used to mutate the original new object.

  [`buildAdapter`](/concepts/adapters#buildadapter) takes 3 possible arguments in each call (after the first):

  1. A selectors object
  2. A function taking in a tuple of `[selectors, reactions]` and returning new reactions
  3. A nested object defining grouped state reactions

  ### 1. Selectors

  [`buildAdapter`](/concepts/adapters#buildadapter) provides full selector memoization and a default `state` selector (after the first call).
  The selectors defined in the first call each receive a state object to select against. Each subsequent selector block has access to all
  selectors previously defined. To return all the selectors combined into an adapter, call it a final time with no parameter.

  #### Example: Basic selectors

  ```typescript
  import { buildAdapter } from '@state-adapt/core';

  const stringAdapter = buildAdapter<string>()({})({
    reverse: s => s.state.split('').reverse().join(''),
  })({
    isPalendrome: s => s.reverse === s.state,
  })();
  ```

  `s` is typed as an object with properties with the same names as all the selectors defined previously, and typed with each corresponding selector's
  return type. Internally, [`buildAdapter`](/concepts/adapters#buildadapter) uses a `Proxy` to detect which selectors your new selector functions are
  accessing in order memoize them efficiently.

  ### 2. Reactions

  #### Example: Basic Reactions

  ```typescript
  import { buildAdapter } from '@state-adapt/core';
  import { numberAdapter } from './number.adapter';

  const numberStringAdapter = buildAdapter<number>()(numberAdapter)({
    negativeStr: s => s.negative.toString(),
  })(([selectors, reactions]) => ({
    setToNegative: state => selectors.negative(state),
  }))();
  ```

  `setToNegative` becomes a reaction on `numberStringAdapter` that multiplies the state by `-1` (the return of `selectors.negative(state)`).

  Selectors used when defining new reactions must be called as functions and will not be memoized. If efficiency is critical, you might want to put the derived state in the action payload for the state change.

  ### 3. Grouped Reactions

  The nested object defining grouped state reactions is for nested states. In the following example, a group state reaction called `setBothNumbers` will set both `coolNumber` and `weirdNumber` to the same payload passed into the new `setBothNumbers` reaction.

  #### Example: Grouped Reactions

  ```typescript
  const numbersAdapter = buildAdapter<NumbersState>()({
    setCoolNumber: (state, newCoolNumber: number) => ({
      ...state,
      coolNumber: newCoolNumber,
    }),
    setWeirdNumber: (state, newWeirdNumber: number) => ({
      ...state,
      weirdNumber: newWeirdNumber,
    }),
  })({
    setBothNumbers: {
      coolNumber: numberAdapter.set,
      weirdNumber: numberAdapter.set,
    },
  })();
  ```

  The new reaction's payload type will be the intersection of the payload types from the reactions used, except when one of the payloads is `void`, in which case it will be ignored in the payload intersection.
 */
function buildAdapter() {
  return (reactionsWithSelectors = {}) => {
    const adapter = createAdapter()(reactionsWithSelectors);
    return addNewBlock({
      reactions: adapter,
      selectors: adapter.selectors
    });
  };
}
function addNewBlock(builder) {
  return newBlock => {
    // Done
    if (!newBlock) return {
      ...builder.reactions,
      selectors: builder.selectors
    };
    // New reactions
    if (typeof newBlock === 'function') {
      const newReactions = newBlock([builder.selectors, builder.reactions]);
      for (const prop in newReactions) {
        builder.reactions[prop] = newReactions[prop];
      }
      return addNewBlock(builder);
    }
    // Selectors and combined reactions
    let type;
    for (const prop in newBlock) {
      type = typeof newBlock[prop] === 'function' ? 'selectors' : 'nestedReactions';
      break;
    }
    // Selectors
    if (type === 'selectors') {
      // Mutates
      combineSelectors()(builder.selectors, newBlock);
      return addNewBlock(builder);
    }
    // Reactions
    for (const prop in newBlock) {
      const reactionGroup = newBlock[prop];
      builder.reactions[prop] = (state, payload, initialState) => {
        let newState;
        for (const subStateName in reactionGroup) {
          const subReaction = reactionGroup[subStateName];
          const subState = state[subStateName];
          const newSubState = subReaction(subState, payload, initialState[subStateName]);
          if (subState !== newSubState) {
            newState = newState || {
              ...state
            };
            newState[subStateName] = newSubState;
          }
        }
        return newState || state;
      };
    }
    return addNewBlock(builder);
  };
}

/**
  ## ![StateAdapt](https://miro.medium.com/max/4800/1*qgM6mFM2Qj6woo5YxDMSrA.webp|width=14) `joinAdapters`

  {@link joinAdapters} creates a complex adapter from simpler adapters by taking each of their state change
  functions and selectors and adding them to the new adapter with more specific names to distinguish them from
  each other. All state reaction names have their adapter's namespaces inserted after the first word, and all selector names
  get prepended with their adapter's namespace.

  The initial {@link joinAdapters} call returns the same thing as the {@link buildAdapter} call, so it can be called again and again with more objects inheriting from previous objects, until a final empty call `()` to get the final built adapter.

  #### Example: Basic `joinAdapters`

  ```tsx
  import { joinAdapters, createAdapter } from '@state-adapt/core';

  interface State {
    a: number;
    b: number;
  }

  const adapter = joinAdapters<NumbersState>()({
    a: createAdapter<number>()({}),
    b: createAdapter<number>()({
      setTo0: state => 0,
      selectors: {
        negative: state => state * -1,
      },
    }),
  })();
  ```

  This is the same as:

  ```tsx
  import { createAdapter } from '@state-adapt/core';

  interface State {
    a: number;
    b: number;
  }

  const adapter = createAdapter<State>()({
    setA: (state, newA: number) => ({...state, a: newA}),
    resetA: (state, payload: void, initialState) => ({...state, a: initialState.a}),
    setBTo0: (state, payload: void) => ({...state, b: 0}),
    setB: (state, newB: number) => ({...state, b: newB}),
    resetB: (state, payload: void, initialState) => ({...state, b: initialState.b}),
    set: (state, newState: State) => newState,
    reset: (state, payload: void, initialState) => initialState,
    update: (state, newState: Partial<State>) => ({...state, ...newState}),
    selectors: {
      a: state => state.a,
      b: state => state.b,
      bNegative: state => state.b * -1,
      state: state => state,
    },
  });
  ```

  #### Example: `joinAdapters` with `buildAdapters`-like syntax

  ```tsx
  import { joinAdapters, createAdapter } from '@state-adapt/core';
  import { numberAdapter } from './number.adapter';

  interface NumbersState {
    a: number;
    b: number;
  }

  const adapter = joinAdapters<NumbersState>()({
    a: numberAdapter,
    b: numberAdapter,
  })({
    // Selectors
    total: s => s.a + s.b,
  })({
    // Group reactions
    incrementAll: {
      a: numberAdapter.increment,
      b: numberAdapter.increment,
    },
  })(([selectors, reactions]) => ({
    // More reactions
    addBToA: state => ({ ...state, a: state.a + selectors.b(state) }),
    addAToB: state => ({ ...state, b: state.b + selectors.a(state) }),
  }))();
  ```

  For more details, see {@link buildAdapter}.

  #### Example: Auth

  ```tsx
  import { joinAdapters, createAdapter } from '@state-adapt/core';

  interface AuthState {
    username: string | null;
    password: string | null;
    isLoggedIn: boolean;
  }

  const authAdapter = joinAdapters<AuthState>()({
    username: createAdapter<string | null>()({}),
    password: createAdapter<string | null>()({}),
    isLoggedIn: createAdapter<boolean>()({
      login: () => true,
      logout: () => false,
    }),
  })();

  // Usage
  const initialState = { username: null, password: null, isLoggedIn: false };
  const newState = authAdapter.update(initialState, {
    username: 'bob',
    password: '1234',
  });
  // { username: 'bob', password '1234', isLoggedIn: false }
  ```

  #### Example: Cookies

  ```tsx
  import { joinAdapters, createAdapter } from '@state-adapt/core';

  interface CookieState {
    price: number;
    flavor: 'Chocolate Chip' | 'Oatmeal Raisin';
  }

  const cookieAdapter = joinAdapters<CookieState>()({
    price: createAdapter<number>()({
      selectors: {
        discounted: state => state * 0.9,
      },
    }),
    flavor: createAdapter<Flavor>()({
      setToChocolateChip: () => 'Chocolate Chip',
      setToOatmealRaisin: () => 'Oatmeal Raisin',
    }),
  })();

  interface CookiesState {
    favorite: CookieState;
    leastFavorite: CookieState;
  }
  const initialCookiesState: CookiesState = {
    favorite: {
      price: 200,
      flavor: 'Chocolate Chip',
    },
    leastFavorite: {
      price: 190,
      flavor: 'Oatmeal Raisin',
    },
  };

  const cookiesAdapter = joinAdapters<CookiesState>()({
    favorite: cookieAdapter,
    leastFavorite: cookieAdapter,
  })({
    totalPrice: s => s.favorite.price + s.leastFavorite.price,
  })({
    totalPriceDiscounted: s => s.totalPrice * 0.9,
  })();

  // Usage
  cookiesAdapter.setFavoriteToOatmealRaisin(initialCookiesState);
  cookiesAdapter.setLeastFavoriteToOatmealRaisin(initialCookiesState);
  const favoritePriceDiscounted =
    cookiesAdapter.selectors.favoritePriceDiscounted(initialCookiesState);
  const totalPrice = cookiesAdapter.selectors.totalPrice(initialCookiesState);
  const totalPriceDiscounted =
    cookiesAdapter.selectors.totalPriceDiscounted(initialCookiesState);
  ```

  #### Example: Olympic Sports

  ```tsx
  import { joinAdapters, createAdapter } from '@state-adapt/core';

  interface SportState {
    name: string;
    isOlympic: boolean;
  }

  const sportAdapter = joinAdapters<SportState>()({
    name: createAdapter<string>()({
      setToSoccer: () => 'soccer',
      setToBasketball: () => 'basketball',
    }),
    isOlympic: createAdapter<boolean>()({
      setToTrue: () => true,
      setToFalse: () => false,
    }),
  })({
    isOlympicAndSoccer: s => s.isOlympic && s.name === 'soccer',
    isOlympicAndBasketball: s => s.isOlympic && s.name === 'basketball',
  })({
    setToOlympicSoccer: {
      name: () => 'soccer',
      isOlympic: () => true,
    },
  })();
  ```

 */
function joinAdapters() {
  return adapterEntries => {
    const joinedAdapters = createAdapter()({});
    joinedAdapters.update = createUpdateReaction();
    const ignoredKeys = ['noop', 'selectors'];
    for (const namespace in adapterEntries) {
      const adapter = adapterEntries[namespace];
      for (const reactionName in adapter) {
        if (ignoredKeys.includes(reactionName)) continue;
        const firstCapIdx = reactionName.match(/(?=[A-Z])/)?.index ?? reactionName.length;
        const verb = reactionName.substring(0, firstCapIdx);
        const rest = reactionName.substring(firstCapIdx);
        const newReactionName = `${verb}${namespace.charAt(0).toUpperCase() + namespace.substr(1)}${rest}`;
        joinedAdapters[newReactionName] = (state, payload, initialState) => ({
          ...state,
          [namespace]: adapter[reactionName](state[namespace], payload, initialState[namespace])
        });
      }
      joinedAdapters.selectors[namespace] = getMemoizedSelector(namespace, state => state[namespace]);
      if (adapter['selectors']) {
        for (const selectorName in adapter['selectors']) {
          const selector = adapter['selectors'][selectorName];
          const newSelectorName = `${namespace}${selectorName.charAt(0).toUpperCase() + selectorName.substring(1)}`;
          joinedAdapters.selectors[newSelectorName] = getMemoizedSelector(newSelectorName, (state, cache) => selector(state[namespace], cache), parentCache => {
            const children = parentCache.__children;
            return children[namespace] = children[namespace] || createSelectorsCache();
          });
        }
      }
    }
    return buildAdapter()(joinedAdapters);
  };
}
const adaptType = 'Adapt';
function isPatchState(action) {
  return action.type === adaptType;
}
function createPatchState(source, payload) {
  return {
    type: adaptType,
    source,
    payload
  };
}
function createInit(path, initialState) {
  return createPatchState({
    type: `INIT ${path}`
  }, [[path.split('.'), initialState]]);
}
function createDestroy(path) {
  return createPatchState({
    type: `DESTROY ${path}`
  }, [[path.split('.'), undefined]]);
}
function actionSanitizer(action) {
  return isPatchState(action) ? {
    ...action,
    action: undefined,
    actionType: undefined,
    source: undefined,
    payload: action.source.payload,
    type: action.source.type
  } : action;
}
function stateSanitizer(state) {
  return {
    ...state,
    ...state.adapt,
    adapt: undefined,
    _prevSelectors: globalSelectorsOptions.devtools ? serializeSelectorsCache(globalSelectorsCache) : undefined
  };
}
function adaptReducer(state = {}, action) {
  return isPatchState(action) ? updatePaths(state, action.payload) : state;
}
function createAdaptNestedReducer(adaptReducer) {
  return (state, action) => {
    const newState = adaptReducer(state?.adapt, action);
    if (newState === state) {
      return state;
    }
    return {
      ...state,
      adapt: newState
    };
  };
}

/**
  This will be replaced by the cheat sheet aggregator after the build.
  scripts/aggregate-cheat-sheet.js
*/
const coreCheatSheet = {};

/**
 * Generated bundle index. Do not edit.
 */

export { actionSanitizer, adaptReducer, adaptType, addNewBlock, buildAdapter, combineSelectors, coreCheatSheet, createAdaptNestedReducer, createAdapter, createDestroy, createInit, createNoopReaction, createPatchState, createReactions, createSelectorsCache, createUpdateReaction, expectType, flatten, getAction, getId, getMemoizedSelector, globalSelectorsCache, globalSelectorsOptions, isPatchState, joinAdapters, mapPayloads, mapToSelectorsWithCache, memoizeSelectors, memoizeWithProxy, patch, prefixAction, serializeSelectorsCache, stateSanitizer, updatePaths };
