import { HttpClient } from '@angular/common/http';
import { Injectable, InjectionToken, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { API_URL } from './api_url';

export const API_CLIENT = new InjectionToken('API Client', {
  providedIn: 'root',
  /**
   * Returns an instance of the ApiClientService.
   *
   * @returns {ApiClientService} - The ApiClientService instance.
   */
  factory: (): ApiClientService => inject(ApiClientService),
});

@Injectable({
  providedIn: 'root',
})
export class ApiClientService {
  #http = inject(HttpClient);
  #apiUrl = inject(API_URL);

  private _apiVersion: string = 'v1';

  /**
   * Retrieve the API version.
   *
   * @return {string} The API version.
   */
  public get getApiVersion(): string {
    return this._apiVersion;
  }

  /**
   * Sets the API version.
   *
   * @param {string} value - The value of the API version.
   */
  public set apiVersion(value: string) {
    this._apiVersion = value;
  }

  /**
   * Sets the API version for the object.
   *
   * @param {string} value - The API version to set.
   * @return {this} - Returns the updated object.
   */
  public asApiVersion(value: string): this {
    this.apiVersion = value;
    return this;
  }

  /**
   * Sends a create request to the specified URL with an optional request body.
   *
   * @param {string} url - The URL to send the request to.
   * @param {D} body - The request body (optional).
   * @return {Observable<T>} - An observable that resolves with the response data.
   */
  sendCreateRequest<T, D>(url: string, body?: D): Observable<T> {
    return this.#http.post<T>(`${this.#apiUrl}/${this.getApiVersion}/${url.replace('/', '')}`, JSON.stringify(body));
  }

  /**
   * Sends a read request to the specified URL and returns an observable of type T.
   *
   * @param {string} url - The URL to send the read request to.
   * @return {Observable<T>} - An observable of type T representing the response data.
   */
  sendReadRequest<T>(url: string): Observable<T> {
    return this.#http.get<T>(`${this.#apiUrl}/${this.getApiVersion}/${url.replace('/', '')}`);
  }

  /**
   * Sends an update request to the specified URL.
   *
   * @param {string} url - The URL to send the update request to.
   * @param {D} body - The body of the request (optional).
   * @return {Observable<T>} The response as an Observable of type T.
   */
  sendUpdateRequest<T, D>(url: string, body?: D): Observable<T> {
    return this.#http.patch<T>(`${this.#apiUrl}/${this.getApiVersion}/${url.replace('/', '')}`, JSON.stringify(body));
  }

  /**
   * Sends a DELETE request to the specified URL.
   *
   * @param {string} url - The URL to send the request to.
   * @return {Observable<T>} - An observable that emits the response data.
   */
  sendDeleteRequest<T>(url: string): Observable<T> {
    return this.#http.delete<T>(`${this.#apiUrl}/${this.getApiVersion}/${url.replace('/', '')}`);
  }
}
